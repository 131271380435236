import React, { useState } from 'react';
import { Link } from 'react-scroll';

import logo from '../images/Dickenson_Logo_Stacked.png';

const Header = () => {
  const [isNavOpen, setNav] = useState(false);
  let navClass = `collapse navbar-collapse ${isNavOpen ? 'show' : ''}`;

  return (
    <nav
      className="sticky-top"
      style={{ backgroundColor: 'rgba(250,250,250,.95)' }}
    >
      <div className="alert bg-secondary rounded-0 py-1 mb-0">
        <div className="container text-white small font-weight-bold">
          <a href="tel:8656884442" className="text-white link mr-lg-4  mr-2">
            865-688-4442
          </a>
          <a
            href="mailto:dickensondental@gmail.com"
            className="text-white link ml-lg-2 mr-lg-4 ml-1 mr-2 text-uppercase"
          >
            dickensondental@gmail.com
          </a>
        </div>
      </div>

      <div className="container navbar navbar-expand-lg navbar-light py-3">
        <Link
          to=""
          smooth={true}
          offset={-125}
          className="navbar-brand"
          style={{ width: '200px' }}
        >
          <img className="w-100" src={logo} alt="" />
        </Link>
        <button
          onClick={() => setNav(!isNavOpen)}
          className="navbar-toggler"
          type="button"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className={navClass}>
          <div className="navbar-nav ml-auto">
            <li className="nav-item text-center my-1 mx-2">
              <Link
                to="about"
                smooth={true}
                offset={-125}
                spy={true}

                activeClass="active"
                className="nav-link text-secondary"
                onClick={() => setNav(false)}
              >
                About
              </Link>
            </li>
            {/*<li className="nav-item text-center my-1 mx-2">*/}
            {/*  <Link*/}
            {/*    to="services"*/}
            {/*    smooth={true}*/}
            {/*    offset={-125}*/}
            {/*    spy={true}*/}
            {/*    */}
            {/*    activeClass="active"*/}
            {/*    className="nav-link text-secondary"*/}
            {/*    onClick={() => setNav(false)}*/}
            {/*  >*/}
            {/*    Services*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className="nav-item text-center my-1 mx-2">
              <Link
                to="location"
                smooth={true}
                offset={-125}
                spy={true}

                activeClass="active"
                className="nav-link text-secondary"
                onClick={() => setNav(false)}
              >
                Location
              </Link>
            </li>
            <li className="nav-item text-center my-1 mx-2">
              <Link
                to="contact"
                smooth={true}
                offset={-125}
                spy={true}

                activeClass="active"
                className="nav-link text-secondary"
                onClick={() => setNav(false)}
              >
                Contact
              </Link>
            </li>
          </div>
        </div>
      </div>
    </nav>
  );
};

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// };
//
// Header.defaultProps = {
//   siteTitle: '',
// };

export default React.memo(Header);
